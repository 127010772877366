import { useState, useEffect } from 'react';
function Content() {
    const [comment, setComment] = useState('');
    const [comments, setComments] = useState(() => {
        return JSON.parse(localStorage.getItem('Content')) || [];
    });
    function submitComment() {
        if (comment) {
            let Content = [...comments, comment];
            localStorage.setItem('Content', JSON.stringify(Content));
            setComments(Content);
            setComment('');
        }
    }
    return (
        <div className="App">
            <div className="view-chat">
                <div className="profile">
                    <img src="https://i.imgur.com/yLemLUl.png" alt="" />
                    <span>Phạm Hoàng Tuấn</span>
                </div>
                <ul>
                    {comments.map((item, index) => {
                        return <li key={index}>{item}</li>;
                    })}
                </ul>
            </div>
            <div className="view-control">
                <form>
                    <input
                        name=""
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        id=""
                        placeholder="Aa"
                    ></input>
                </form>
                <button onClick={submitComment}>Gửi</button>
            </div>
        </div>
    );
}
export default Content;
