import './App.css';
import { useState, useEffect } from 'react';
import Content from './Content';

function App() {
    const [show, setShow] = useState(false);
    return (
        // <div className="App">
        //     <button onClick={() => setShow(!show)}>Toggle</button>
        //     {show && <Content />}
        // </div>
        <div className="App">
            <Content />
        </div>
    );
}

export default App;
